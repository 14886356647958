(function(){
    function templateContent(template) {
        if ('content' in document.createElement('template')) {
            return template.content;
        } else {
            var fragment = document.createDocumentFragment();
            var children = template.childNodes;
            for (i = 0; i < children.length; i++) {
                fragment.appendChild(children[i].cloneNode(true));
            }
            return fragment;
        }
    }

    function fillTemplate(data, template) {
        var clone = templateContent(template).cloneNode(true);

        // modal title
        var title_content = clone.querySelector('.modal-title').textContent;
        clone.querySelector('.modal-title').textContent = title_content.replace('{{subject}}', data.subject);

        // notification description
        clone.querySelector('.notification-description').textContent = data.description;

        // form action
        var form = clone.querySelector('.follow-notify-form');
        form.action = __tnt.scrubUrl('/search/savedsearch/save/' + data.params);
        form.dataset.authorId = data.author_id;
        form.dataset.userUrl = __tnt.scrubUrl(data.user_url);

        // manage notifications url
        var manage_url = clone.querySelector('.follow-notify-form a').href;
        clone.querySelector('.follow-notify-form a').href = __tnt.scrubUrl(manage_url);

        // notification name
        clone.querySelector('.input-notification-name').value = data.name;

        return document.importNode(clone, true);
    }

    function followThis() {
        $('#follow-this-modal').modal({show:false});

        var followed_btns = document.querySelectorAll('.followed-notify-btn');
        for (var i=0; i<followed_btns.length; i++) {
            if (followed_btns[i].tagName == 'A') {
                followed_btns[i].style.display = 'inline';
            } else if (followed_btns[i].tagName == 'BUTTON') {
                followed_btns[i].disabled = false;
            }
        }

        document.addEventListener('click', function(e){
            if (!e.target.closest('.followed-notify-btn')) return;
            e.preventDefault();

            var followed_btn = e.target.closest('.followed-notify-btn');            

            $('#follow-this-modal').modal('show');
            var modal = document.getElementById('follow-this-modal');
            var template;

            if (__tnt.user.loggedIn) {
                var data = {
                    'subject': followed_btn.dataset.subject,
                    'description': followed_btn.dataset.description,
                    'params': followed_btn.dataset.params,
                    'name': followed_btn.dataset.name,
                    'author_id': followed_btn.dataset.authorId,
                    'user_url': followed_btn.dataset.userUrl
                };

                template = document.getElementById('follow-this-modal-logged-in');
                var el = fillTemplate(data, template);

                modal.replaceChild(el, modal.firstElementChild);

            } else {
                template = document.getElementById('follow-this-modal-logged-out');
                modal.replaceChild(templateContent(template).cloneNode(true), modal.firstElementChild);

                var links = modal.querySelectorAll('a');
                for (var i=0; i<links.length; i++) {
                    links[i].href = __tnt.scrubUrl(links[i].href);
                    if (links[i].href.indexOf(encodeURIComponent('?')) < 0) {
                        links[i].href += encodeURIComponent('?followid=' + followed_btn.id);
                    } else {
                        links[i].href += encodeURIComponent('&followid=' + followed_btn.id);
                    }
                }
            }
        });

        var modal = document.getElementById('follow-this-modal');
        modal.addEventListener('submit', function(e) {
            e.preventDefault();

            if (e.target.tagName == 'FORM') {
                var form = e.target;
                var followBtn = form.querySelector('.follow-btn');
                followBtn.querySelector('.tnt-svg').style.display = 'inline-block';
                followBtn.disabled = true;

                function submit_finished(alert){
                    setTimeout(function(){
                        followBtn.querySelector('.tnt-svg').style.display = 'none';
                        alert.style.display = 'block';
                        setTimeout(function() {
                            alert.style.transition = 'opacity 400ms ease-in-out';
                            alert.style.opacity = 0;
                            alert.addEventListener('transitionend', function fn() { this.style.display = 'none'; this.removeEventListener('transitionend', fn) });
                        }, 2500);
                    }, 2000);
                }

                var xhr = new XMLHttpRequest();
                var url = form.action + '&title=' + form.title.value;
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                xhr.onreadystatechange = function() {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            submit_finished(modal.querySelector('.alert-success'));
                            if (form.dataset.authorId) {
                                // Track follow event
                                __tnt.trackEvent({
                                    'network': 'Site',
                                    'socialAction': 'Follow',
                                    'url': form.dataset.userUrl + 'profile/' + form.dataset.authorId
                                });
                            }
                        } else {
                            submit_finished(modal.querySelector('.alert-danger'));
                        }
                    }
                }
                xhr.send();
            }
        });

        var urlParams = new URLSearchParams(window.location.search);
        var followId = urlParams.get('followid');
        if (followId && document.getElementById(followId)) {
            document.getElementById(followId).click();
        }
    }

    if (document.readyState == 'loading') {
        document.addEventListener('DOMContentLoaded', function() {
            followThis();
        });
    } else {
        followThis();
    }
})();